import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SupportComponent } from './pages/support/support.component';
import { GlobeComponent } from './pages/globe/globe.component';
import { OnlineGuardService } from './services/online-guard.service';
import { ErrorComponent } from './components/error/error.component';
import { BlogComponent } from './pages/Blogs/blog.component';
import { BlogDetailsComponent } from './pages/Blogs/blog-details/blog-details.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsAndCondtionsComponent } from './pages/terms-and-condtions/terms-and-condtions.component';
import { SearchComponent } from './pages/search/search.component';
import { DownloadpdfThankyouComponent } from './components/urlmodal/downloadpdf-thankyou/downloadpdf-thankyou.component';
import { ConexathankyouComponent } from './components/urlmodal/conexathankyou/conexathankyou.component';
import { ThankyoumodalComponent } from './components/urlmodal/thankyoumodal/thankyoumodal.component';
import { CreateemailthankyouComponent } from './components/urlmodal/createemailthankyou/createemailthankyou.component';


// import { AboutModule } from './pages/About/about.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/Index/index.module').then(m => m.IndexModule),
    canActivate: [OnlineGuardService],
  },
  {
    path: 'freight',
    loadChildren: () => import('./pages/Freight/freight.module').then(m => m.FreightModule),
    canActivate: [OnlineGuardService],
  },

  {
    path: 'thankyou',
    component: ThankyoumodalComponent,
    outlet: 'modal'
  },
  {
    path: 'downloadthankyou',
    component: DownloadpdfThankyouComponent,
    outlet: 'modal'
  },
  {
    path: 'emailthankyou',
    component: CreateemailthankyouComponent,
    outlet: 'modal'
  },
  {
    path: 'conexathankyou',
    component: ConexathankyouComponent,
    outlet: 'modal'
  },

  {
    path: 'services',
    loadChildren: () => import('./pages/Services/services.module').then(m => m.ServicesModule),
    canActivate: [OnlineGuardService],
  },
  {
    path: 'industries',
    loadChildren: () => import('./pages/Industries/industries.module').then(m => m.IndustriesModule),
    canActivate: [OnlineGuardService],
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/About/about.module').then(m => m.AboutModule),
    canActivate: [OnlineGuardService],
  },
  {
    path: 'MyIntercargo',
    loadChildren: () => import('./pages/My-Intercargo/my-intercargo.module').then(m => m.MyIntercargoModule),
    canActivate: [OnlineGuardService],
  },
  {
    path: 'members-hub',
    loadChildren: () => import('./pages/Members-Hub/members-hub.module').then(m => m.MembersHubModule),
    canActivate: [OnlineGuardService],
  },
  {
    path: 'support',
    component: SupportComponent,
    canActivate: [OnlineGuardService],
  },
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [OnlineGuardService],
  },
  {
    path: 'globe',
    component: GlobeComponent,
    canActivate: [OnlineGuardService],
  },
  {
    path: 'blog',
    component: BlogComponent,
    canActivate: [OnlineGuardService],
  },
  {
    path: 'blog-details/:id',
    component: BlogDetailsComponent,
    canActivate: [OnlineGuardService]
  },
  {
    path: 'blog-details',
    component: BlogDetailsComponent,
    canActivate: [OnlineGuardService]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    canActivate: [OnlineGuardService],
  },
  {
    path: 'terms&conditions',
    component: TermsAndCondtionsComponent,
    canActivate: [OnlineGuardService],
  },
  {
    path: 'ConnectionLost',
    component: ErrorComponent,
    data: { errorType: 'Connection Lost' },
  },

  { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled', // Automatically restores the scroll position to top on navigation
      // preloadingStrategy: PreloadAllModules
    }
  ),

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
