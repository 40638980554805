import { Component, EventEmitter, Inject, Output} from '@angular/core';
import { Router , ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-createemailthankyou',
  templateUrl: './createemailthankyou.component.html',
  styleUrl: './createemailthankyou.component.css'
})
export class CreateemailthankyouComponent {
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router : Router,
  ) {
  }
 
  
  closeModal( $event: any ) {
    this.router.navigate([{outlets: {modal: null}}]);
    this.modalClose.next($event);
  }
}
