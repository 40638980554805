<div class="container-fluid">
  <div class="row d-flex h-100">
    <!-- Image Column -->
    <div class="col-12 col-lg-6 d-flex align-items-center  custom-stack">
      <div class="image-support" *ngIf="!showMap" #mapDiv>
        <img [src]="supportImg" class="img-fluid support-img " alt="Support Image" />
      </div>
      <div *ngIf="showMap" id="map" class="img-fluid w-100">
        <iframe class="img-fluid" style="height: 85vh !important" width="100%" height="900" frameborder="0"
          scrolling="no" marginheight="0" marginwidth="0" [src]="iframeSrcs[mapIndex]">
        </iframe>
      </div>
    </div>


    <!-- Content Column -->
    <div class="col-12 col-lg-6 d-flex flex-column custom-stack">
      <!-- Navigation Bar -->
      <nav class="nav p-3" style="background-color: var(--supportTabHeadingBG)">
        <button class="nav-link me-xl-3 py-xl-2 px-xl-4 me-xxl-5 py-xxl-3 px-xxl-5" (click)="setActiveTab('support')"
          [ngClass]="{ active: activeTab === 'support' }">
          Customer Support
        </button>
        <button class="nav-link me-xl-3 py-xl-2 px-xl-4 me-xxl-5 py-xxl-3 px-xxl-5" (click)="setActiveTab('feedback')"
          [ngClass]="{ active: activeTab === 'feedback' }">
          Feedback
        </button>
      </nav>

      <!-- Tab Content Area -->
      <div class="flex-fill d-flex flex-column text-start ps-3 pe-3 pe-lg-5 panel"
        style="background-color: var(--supportTabContentBG)">
        <div *ngIf="activeTab === 'support'">
          <div class="row d-flex align-items-center my-xl-4 my-xxl-5 ms-xxl-4">
            <div class="col-xl-6 col-xxl-8">
              <h2 class="text-primary">View contact information for :</h2>
            </div>
            <div class="col-xl-6 col-xxl-4">
              <select aria-label="Select a country" class="form-select" [(ngModel)]="selectedCountry"
                (change)="contactInfo()">
                <option value="USA">USA</option>
                <option value="CA">Canada</option>
                <option value="UK" selected>United Kingdom</option>
                <option value="Ireland">Ireland</option>
                <option value="PK">Pakistan</option>
                <option value="AE">UAE</option>
              </select>
            </div>
          </div>

          <div class="row align-items-center ms-xxl-4 my-xl-2 my-xxl-4">
            <div class="col-xl-6 col-xxl-8">
              <h2>Phone</h2>
              <p>
                Our Client Advisors are available Monday through Sunday 24/7
              </p>
            </div>
            <div class="col-xl-6 col-xxl-4 d-flex justify-content-lg-end">
              <a class="btn btn-outline-primary w-100 d-flex align-items-center justify-content-center"
                [href]="'tel:' + phone()">
                Call Us {{ phone() }}
              </a>
            </div>
          </div>
          <hr />

          <div class="row align-items-center ms-xxl-4 my-xl-3 my-xxl-4">
            <div class="col-xl-6 col-xxl-8">
              <h2>Live Chat</h2>
              <p>Chat with our Online Client Advisors</p>
            </div>
            <div class="col-xl-6 col-xxl-4 d-flex justify-content-lg-end">
              <button class="btn btn-outline-primary w-100" (click)="loadZendeskWidget()">
                Start Live Chat
              </button>
            </div>
          </div>
          <hr />

          <div class="row align-items-center ms-xxl-4 my-xl-3 my-xxl-4">
            <div class="col-xl-6 col-xxl-8">
              <h2>Email</h2>
              <p>
                Click "Create Email" to provide details and your contact
                information
              </p>
            </div>
            <div class="col-xl-6 col-xxl-4 d-flex justify-content-lg-end">
              <button class="btn btn-outline-primary w-100" (click)="openModal()">
                Create Email
              </button>
            </div>
          </div>
          <hr />

          <div class="row ms-xxl-4 my-xl-0 my-xxl-5">
            <div class="col-xl-6 col-xxl-8 d-flex flex-column justify-content-between">
              <h2>Address</h2>
              <a *ngIf="!showMap" (click)="viewMap()" class="text-primary" style="cursor: pointer">
                View Address on Map
              </a>
              <a *ngIf="showMap" (click)="viewMap()" class="text-primary" style="cursor: pointer">
                Hide Map
              </a>
            </div>
            <div class="col-xl-6 col-xxl-4 d-flex justify-content-lg-start">
              <p [innerHTML]="address()"></p>
            </div>
          </div>
        </div>

        <div *ngIf="activeTab === 'feedback'" class="p-4 px-2 flex-fill">
          <div class="mt-2">
            <form>
              <h2>How was your journey with us?</h2>
              <div class="row">
                <div class="col-4 col-lg-2 text-center" *ngFor="let rating of ratings()">
                  <img [src]="rating.img" alt="{{ rating.label }}" class="emoji" (click)="setRating(rating)" />
                  <div class="mt-2">{{ rating.label }}</div>
                </div>
              </div>
              <div class="row mt-5 d-flex justify-content-between">
                <div class="col-lg-6 col-12 text-start">
                  First Name:
                  <input class="form-control" placeholder="Enter your first name" [(ngModel)]="firstname"
                    name="firstname" style="height: 3rem !important; " required>
                </div>
                <div class="col-lg-6 col-12 text-start pt-3 pt-lg-0">
                  Last Name:
                  <input class="form-control" placeholder="Enter your last name" [(ngModel)]="lastname" name="lastname"
                    style="height: 3rem !important; " required>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 text-start">
                  Email:
                  <input class="form-control" type="email" placeholder="Enter your email" [(ngModel)]="email"
                    name="email" style="height: 3rem !important; ">
                </div>
              </div>
              <div class="my-3">
                <!-- <label for="comments" class="form-label"> -->
                <h2 class="ms-1">Share your thoughts</h2>
                <!-- </label> -->
                <textarea class="form-control" id="comments" rows="3" [(ngModel)]="textAreaContent" name="comments"
                  placeholder="Add your comments..."></textarea>
              </div>
              <button class="btn btn-primary" (click)="shareFeedback()">Submit Feedback</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>