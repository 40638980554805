import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DarkModeService } from './services/dark-mode.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'], // Fixed 'styleUrl' to 'styleUrls'
})
export class AppComponent {
  isOnline: boolean | undefined;

  constructor(
    private http: HttpClient,
    private darkModeService: DarkModeService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.darkModeService.darkMode$.subscribe((isDarkMode) => {
      if (isPlatformBrowser(this.platformId)) {
        document.body.classList.toggle('dark-mode', isDarkMode);
      }
    });
  }

  title = 'intercargo_webapp.client';
}
