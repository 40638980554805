<header class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
        <a class="navbar-brand" (click)="handleRoute('')">
            <img [src]="imageSrc" alt="Company Logo" class="logo"
                loading="eager">
        </a>
        <div class="d-lg-none  ">
            <button class="btn" style="color:var(--txtDark)"
                aria-controls="navbarContent" (click)="toggleSearch()">
                <span class="bi bi-search"></span>
            </button>

            <button class="btn" style="color:var(--txtDark)"
                aria-controls="navbarContent">
                <span class="bi bi-globe" routerLink="/globe"></span>
            </button>
            <!-- 
            <button class="btn" style="color:var(--txtDark)" type="button" (click)="toggleNavbar()"
                aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="bi bi-list fs-3"></span>
            </button>
             -->
            <button class="btn" style="color:var(--txtDark)"
                aria-expanded="false" type="button"
                (click)="toggleNavbar()" aria-controls="navbarContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span [ngClass]="{
                                    'bi': true,
                                    'fs-3': true,
                                    'bi-x-lg': !isNavbarCollapsed,
                                    'bi-list': isNavbarCollapsed
                                }"></span>
            </button>
        </div>

        <div class="collapse navbar-collapse" id="navbarContent">
            <ul class="navbar-nav mx-auto">
                <!-- <li class="nav-item  me-3" (mouseenter)="isHovering1 = true" (mouseleave)="isHovering1 = false">
                    <a class="nav-link" (click)="handleRoute('')">Home </a>
                </li> -->
                <li class="nav-item" (mouseenter)="isHovering1 = true"
                    (mouseleave)="isHovering1 = false"
                    [class.stayhovered-nav-item]="solutionMenuVisible">
                    <a class="nav-link" #sText
                        (mouseenter)="showMegaMenu('Solutions')"
                        (mouseleave)="scheduleHideMegaMenu(200)"
                        [class.stayhovered]="solutionMenuVisible">Solutions
                        &nbsp;
                        <i class="bi"
                            [class.bi-chevron-down]="!solutionMenuVisible"
                            [class.bi-chevron-up]="solutionMenuVisible"></i>
                    </a>
                </li>
                <li class="nav-item " (mouseenter)="isHovering2 = true"
                    (mouseleave)="isHovering2 = false"
                    [class.stayhovered-nav-item]="aboutMenuVisible">
                    <a class="nav-link" #aText
                        (mouseenter)="showMegaMenu('About Us')"
                        (mouseleave)="scheduleHideMegaMenu(200)"
                        [class.stayhovered]="aboutMenuVisible">About Us &nbsp;
                        <i
                            class="bi"
                            [class.bi-chevron-down]="!aboutMenuVisible"
                            [class.bi-chevron-up]="aboutMenuVisible"></i></a>
                </li>
                <li class="nav-item " (mouseenter)="isHovering3 = true"
                    (mouseleave)="isHovering3 = false"
                    [class.stayhovered-nav-item]="intercargoMenuVisible">
                    <a class="nav-link" #mText
                        (mouseenter)="showMegaMenu('My Intercargo')"
                        (mouseleave)="scheduleHideMegaMenu(200)"
                        [class.stayhovered]="intercargoMenuVisible">My
                        Intercargo
                        &nbsp; <i class="bi"
                            [class.bi-chevron-down]="!intercargoMenuVisible"
                            [class.bi-chevron-up]="intercargoMenuVisible"></i></a>
                </li>
                <li class="nav-item ">
                    <a class="nav-link" (mouseenter)="scheduleHideMegaMenu(0)"
                        routerLink="support">Support</a>
                </li>
            </ul>
            <a class="nav-link darkMode-btn me-4 btn ps-xl-3 ps-xxl-2"
                (click)="toggleDarkMode()"
                (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
                <i class="bi fs-5" [ngClass]="darkSrc" #{{darkSrc}}></i>
                <span class="label">{{darkLabel}}</span>
            </a>
            <div class="btn-group align-items-center gap-3" role="group">
                <a class="nav-link me-4 btn" (click)="showMegaMenu('Search')">
                    <i class="bi bi-search fs-5"></i>
                </a>
                <a class="nav-link me-4 btn">
                    <i routerLink="/globe" class="bi bi-globe fs-5"></i>
                </a>
            </div>
            <button
                class="btn btn-primary d-flex justify-content-center align-items-center"
                (click)="loginPage()">Login</button>
        </div>
    </div>
</header>

<div [class.collapse]="isNavbarCollapsed" class="mt-2 d-lg-none">
    <div class="text-end p-3">

    </div>

    <div class="text-end p-3 d-flex gap-2 justify-content-end">
        <button class="btn-outline-primary btn ps-xl-3 ps-xxl-2"
            (click)="toggleDarkMode()">
            <i class="bi fs-9 me-2" [ngClass]="darkSrc" #{{darkSrc}}></i>
            <span class="label">{{darkLabel}}</span>
        </button>
        <button class="btn btn-outline-primary px-5 "
            (click)="loginMobClicked()">Login</button>
    </div>

    <mat-accordion multi="true" class="d-lg-none">
        <!-- Only visible in mobile -->
        <mat-expansion-panel class="bg-primary" #solutions>
            <mat-expansion-panel-header class="pt-2"
                style="display: flex !important; align-items: start !important; ">
                <mat-panel-title>
                    <h3 style="color: var(--txtDark);">Solutions</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- <ul class="list-unstyled"> -->
            <h3>FREIGHT</h3>
            <ul>
                <li><a routerLink="/freight/air"
                        (click)="handleLinkClick(solutions)">Air</a></li>
                <li><a routerLink="/freight/sea"
                        (click)="handleLinkClick(solutions)">Sea</a></li>
                <li><a routerLink="/freight/road"
                        (click)="handleLinkClick(solutions)">Road</a></li>
                <li><a routerLink="/freight/rail"
                        (click)="handleLinkClick(solutions)">Rail</a></li>
                <li><a routerLink="/freight/courier"
                        (click)="handleLinkClick(solutions)">Courier</a></li>
            </ul>

            <h3>SERVICES</h3>
            <ul>
                <li><a routerLink="/services/customs-clearance"
                        (click)="handleLinkClick(solutions)">Customs
                        Clearance</a></li>
                <li><a routerLink="/services/warehousing"
                        (click)="handleLinkClick(solutions)">Warehousing</a></li>
                <li><a routerLink="/services/project-cargo"
                        (click)="handleLinkClick(solutions)">Project
                        Cargo</a></li>
                <li><a routerLink="/services/aircraft-charter"
                        (click)="handleLinkClick(solutions)">Aircraft
                        Charter</a>
                </li>
                <li><a routerLink="/services/dangerous-goods"
                        (click)="handleLinkClick(solutions)">Dangerous Goods</a>
                </li>
                <li><a routerLink="/services/perishable-goods"
                        (click)="handleLinkClick(solutions)">Perishable
                        Goods</a>
                </li>
                <li><a routerLink="/services/same-day-delivery"
                        (click)="handleLinkClick(solutions)">Same Day
                        Delivery</a>
                </li>
                <li><a routerLink="/services/order-management"
                        (click)="handleLinkClick(solutions)">Order
                        Management</a>
                </li>
                <li><a routerLink="/services/onboard-courier"
                        (click)="handleLinkClick(solutions)">Onboard Courier</a>
                </li>
                <li><a routerLink="/services/agency-representation"
                        (click)="handleLinkClick(solutions)">Agency
                        Representation</a></li>
            </ul>

            <h3>INDUSTRIES</h3>
            <ul>
                <li><a routerLink="/industries/fashion"
                        (click)="handleLinkClick(solutions)">Fashion</a></li>
                <li><a routerLink="/industries/consumer-goods"
                        (click)="handleLinkClick(solutions)">Consumer Goods</a>
                </li>
                <li><a routerLink="/industries/high-tech&electronics"
                        (click)="handleLinkClick(solutions)">High Tech &
                        Electronics</a></li>
                <li><a routerLink="/industries/pharma&healthcare"
                        (click)="handleLinkClick(solutions)">Pharma &
                        Healthcare</a></li>
                <li><a routerLink="/industries/offshore&ship-spares"
                        (click)="handleLinkClick(solutions)">Offshore & Ship
                        Spares</a></li>
                <li><a routerLink="/industries/aerospace&defence"
                        (click)="handleLinkClick(solutions)">Aerospace &
                        Defence</a></li>
                <li><a routerLink="/industries/e-commerce"
                        (click)="handleLinkClick(solutions)">E-commerce</a></li>
                <li routerLink="/industries/oil&gas"
                    (click)="handleLinkClick(solutions)"><a>Oil & Gas</a></li>
                <li routerLink="/industries/automotive"
                    (click)="handleLinkClick(solutions)"><a>Automotive</a></li>
            </ul>
            <!-- </ul> -->
        </mat-expansion-panel>

        <mat-expansion-panel class="bg-primary" #about>
            <mat-expansion-panel-header class="pt-2"
                style="display: flex !important; align-items: start !important; ">
                <mat-panel-title>
                    <h3 style="color: var(--txtDark);"> About Us</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ul>
                <li><a routerLink="/about/mission-statement"
                        (click)="handleLinkClick(about)">Mission
                        Statement</a></li>
                <li><a routerLink="/about/accreditations"
                        (click)="handleLinkClick(about)">Accreditations</a></li>
                <li><a routerLink="/about/our-partners"
                        (click)="handleLinkClick(about)">Our Partners</a></li>
                <li><a routerLink="/about/why-intercargo"
                        (click)="handleLinkClick(about)">Why Intercargo</a></li>
                <li><a routerLink="/about/how-to-ship"
                        (click)="handleLinkClick(about)">How to Ship</a></li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="bg-primary" #intercargo>
            <mat-expansion-panel-header class="pt-2"
                style="display: flex !important; align-items: start !important; ">
                <mat-panel-title>
                    <h3 style="color: var(--txtDark);"> My Intercargo</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <h3>LOGISTICS HUB</h3>
            <ul>
                <li><a routerLink="/MyIntercargo/conexa"
                        (click)="handleLinkClick(intercargo)">Conexa</a></li>
                <li><a routerLink="/MyIntercargo/track-it"
                        (click)="handleLinkClick(intercargo)">Track it</a></li>
                <li><a routerLink="/MyIntercargo/sailing-schedules"
                        (click)="handleLinkClick(intercargo)">Sailing
                        Schedules</a></li>
                <li><a routerLink="/MyIntercargo/flight-schedules"
                        (click)="handleLinkClick(intercargo)">Flight
                        Schedules</a></li>
                <!-- <li><a routerLink="/MyIntercargo/RoadSchedules" (click)="handleLinkClick(intercargo)">Road Schedules</a>
                </li> -->
                <li><a routerLink="/MyIntercargo/incoterms"
                        (click)="handleLinkClick(intercargo)">Incoterms</a></li>
            </ul>
            <!-- <li><a routerLink="/change-password" (click)="handleLinkClick(intercargo)">Change Password</a></li> -->
            <h3>MEMBERS HUB</h3>
            <ul>
                <li><a routerLink="/members-hub/download-centre"
                        (click)="handleLinkClick(intercargo)">Download
                        Centre</a></li>
                <li><a
                        (click)="handleLinkClick(intercargo, '/members-hub/open-an-account')">Open
                        an Account</a></li>

                <li><a routerLink="/members-hub/careers"
                        (click)="handleLinkClick(intercargo)">Careers</a></li>
                <li><a routerLink="/members-hub/case-study"
                        (click)="handleLinkClick(intercargo)">Case
                        Studies</a></li>
                <li><a routerLink="/members-hub/sustainability"
                        (click)="handleLinkClick(intercargo)">Sustainability</a></li>
                <li><a routerLink="/members-hub/faqs"
                        (click)="handleLinkClick(intercargo)">FAQ's</a></li>
            </ul>
        </mat-expansion-panel>

        <h3 class="ps-4 py-2 text-start bg-primary"><a routerLink="/support"
                class="text-decoration-none"
                style="color: var(--txtDark); font-weight: normal;"
                (click)="handleLinkClick(solutions)">Support</a>
        </h3>

    </mat-accordion>
</div>

<div class="megaMenu container" [class.visible]="solutionMenuVisible"
    (mouseenter)="cancelHideMegaMenu()"
    (mouseleave)="scheduleHideMegaMenu(200)">
    <app-solutions-menu
        (closeMenuEvent)="solutionMenuVisible = false"></app-solutions-menu>
</div>

<div class="megaMenu container" [class.visible]="aboutMenuVisible"
    (mouseenter)="cancelHideMegaMenu()"
    (mouseleave)="scheduleHideMegaMenu(200)">
    <app-about-menu
        (closeMenuEvent)="aboutMenuVisible = false"></app-about-menu>
</div>

<div class="megaMenu container" [class.visible]="intercargoMenuVisible"
    (mouseenter)="cancelHideMegaMenu()"
    (mouseleave)="scheduleHideMegaMenu(200)">
    <app-my-intercargo-menu
        (closeMenuEvent)="intercargoMenuVisible = false"></app-my-intercargo-menu>
</div>

<div class="megaMenu" [class.visible]="SearchMenuVisible">
    <app-search-menu
        (closeMenuEvent)="SearchMenuVisible = false"></app-search-menu>
</div>

<!-- <div class="py-3 shadow-lg d-lg-none" #mobileSearch *ngIf="showMobileSearch">
    <div class="row align-items-center justify-content-center gap-2">
        <input type="text" class="form-control w-50" placeholder="Search">

        <button class="btn btn-primary" type="button">Search</button>
        <i class="bi bi-x" style="font-size: xx-large;"></i>




    </div>

    <div class="row">
    </div>

    <div class="row text-start mt-3 ms-3">
        <p style="font-size: smaller; margin: 0;">Common Searches</p>
        <div class="col d-flex flex-wrap m-0">
            <a href="#" class="me-2">Quick Quotes</a>
            <a href="#" class="me-2">Track Shipment</a>
            <a href="#" class="me-2">Flight Schedules</a>
            <a href="#" class="me-2">Air Freight</a>
        </div>
    </div>
</div> -->

<div class="py-3 shadow-lg d-lg-none overflow-hidden" #mobileSearch
    *ngIf="showMobileSearch">
    <div class="row align-items-center justify-content-center gap-2">
        <div class="col-5 pe-0">
            <input type="text" class="form-control" [(ngModel)]="searchedString"
                placeholder="Search">
        </div>
        <div class="col-auto ps-0">
            <button class="btn btn-secondary" (click)="searchBtnClicked()"
                type="button">Search</button>
        </div>
        <div class="col-auto">
            <i class="bi bi-x" (click)="toggleSearch()"
                style="font-size: xx-large; cursor: pointer;"></i>
        </div>
    </div>

    <div class="row text-start mt-3 ms-3">
        <p style="font-size: smaller; margin: 0;">Common Searches</p>
        <div class="col d-flex flex-wrap m-0">
            <!-- <a href="#" class="me-2">Quick Quotes</a> -->
            <a routerLink="/MyIntercargo/track-it" class="me-2"
                (click)="hideSearch()">Track Shipment</a>
            <a routerLink="/MyIntercargo/flight-schedules" class="me-2"
                (click)="hideSearch()">Flight Schedules</a>
            <a routerLink="/freight/air" class="me-2" (click)="hideSearch()">Air
                Freight</a>
        </div>
    </div>
</div>