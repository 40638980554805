<div class="cookie-overlay" [ngClass]="{ 'show': showBanner }"></div>

<div class="cookie-consent-banner py-5 px-3 px-xxl-5 d-lg-flex  align-items-center justify-content-between"
    [ngClass]="{ 'show': showBanner }" *ngIf="showBanner">

    <div class="d-lg-flex col-lg-5 col-12 align-items-center">
        <div class="col-lg-1 col-12 me-lg-4 me-0 order-1">
            <img src="../../../assets/imgs/cookies.png" alt="Cookie Icon" class="img-fluid">
        </div>
        <p class="mb-0 text-lg-start text-center col-12 order-2 px-lg-0 px-5 mt-5 mt-lg-0">Our website uses cookies. By continuing navigating, we assume your permission to
            deploy
            cookies as detailed in our <a routerLink="/privacy-policy">Privacy Policy</a>.</p>
    </div>
    <div class="mt-5 mt-lg-0 px-2 px-lg-0 col-lg-6" >
        <button class="btn btn-secondary me-2 col-lg-4 col-12 order-1 mb-2 mb-lg-0" (click)="acceptCookies()"> <img src="../../../assets/icons/cookie.png"
                alt="" class="img-fluid me-1" width="20px"> Accept cookies</button>
        <button class="btn btn-outline-primary me-2 col-lg-4 col-12 order-2 mb-2 mb-lg-0" (click)="showCustomSettings()"> Customize Settings</button>

        <!-- <button class="btn btn-outline-primary col-lg-2 col-12 order-2 mb-2 mb-lg-0" (click)="declineCookies()"> Decline</button> -->
    </div>
</div>

<div class="custom-settings-panel px-4 py-5 justify-content-between col-12" [ngClass]="{ 'show': showSettingsPanel }">
    <div class="text-start">
        <img src="../../../assets/imgs/logos/LogoWhiteGreen.png" alt="">
    </div>
    <hr>
    <div class="text-start">
        <h3 class="text-start">Privacy Preference Center</h3>
        <p style="font-size: smaller;">
            This website uses cookies and similar technologies, (hereafter “technologies”), which enable us, for
            example, to
            determine how frequently our internet pages are visited, the number of visitors, to configure our offers for
            maximum convenience and efficiency and to support our marketing efforts. These technologies incorporate data
            transfers to third-party providers based in countries without an adequate level of data protection (e. g.
            United
            States). For further information, including the processing of data by third-party providers and the
            possibility
            of revoking your consent at any time, please see your settings under “Consent Preferences” and our </p>

        <br /> <a routerLink="/privacy-policy" >Privacy Notice</a>
        <br /> <a href="">Legal notice</a><br />
        <br /> <button class="btn btn-outline-success text-white w-50"> Accept All</button>
    </div>
    <hr>
    <div class="text-start">
        <h3 class="text-start">Privacy Preference Center</h3>
        <div class="row">
            <div class="col-8">
                <i class="bi bi-plus"></i> Strickly Necessary Cookies
            </div>
            <div class="col-4 d-flex justify-content-end text-nowrap" style="color: #5FBF92;">
                Always Active
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-8">
                <i class="bi bi-plus"></i> Performance Cookies
            </div>
            <div class="col-4 d-flex justify-content-end">
                <div class="form-check form-switch">
                    <input class="form-check-input" [(ngModel)]="performanceCookies" type="checkbox" role="switch" name="performanceCookies" placeholder="N/A">
                  </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-8">
                <i class="bi bi-plus"></i> Functional Cookies
            </div>
            <div class="col-4 d-flex justify-content-end">
                <div class="form-check form-switch">
                    <input class="form-check-input" [(ngModel)]="functionalCookies" type="checkbox" role="switch" name="functionalCookies" placeholder="N/A">
                  </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-8">
                <i class="bi bi-plus"></i> Targeting Cookies
            </div>
            <div class="col-4 d-flex justify-content-end">
                <div class="form-check form-switch">
                    <input class="form-check-input" [(ngModel)]="targetingCookies" type="checkbox" role="switch" name="targetingCookies" placeholder="N/A">
                  </div>
            </div>
        </div>
        <hr>
    </div>

    <div class="text-start">
        <button class="btn btn-success px-4" (click)="confirmSelection()">Confirm Selection</button>
    </div>
</div>